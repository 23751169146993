.clock {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title {
  font-size: 52px;
  font-weight: 600;
}

.control {
  display: flex;
}

#break-label, #session-label {
  padding: 25px;
  font-size: 24px;
}

.wrapper {
  display: flex;
  justify-content: space-around;
  font-weight: 700;
  font-size: 32px;
}

#timer-label {
  border: 8px solid orange;
  border-radius: 50px;
  padding: 52px;
  font-size: 48px;
  text-align: center;
}

#regulator {
  display: flex;
  font-size: 40px;
  margin-top: 16px;
}

#start_stop {
  padding-right: 6px;
}

